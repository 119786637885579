.card-img-top {
    width: 100%;
    height: 200px !important;
    object-fit: cover;
    border-radius: .5rem .5rem 0rem 0rem;
}
.card_active{
    background-color: #045243a9;
    padding: 5px;
    /* border: 1px solid #69338d; */
    border-radius: 1rem 1rem 1rem 1rem;
    color: #fff;
}
