/* .product_img{
    height: 70vh;
}
@media only screen and (max-width: 480px){
    .product_img{
    height: 40vh;
    }
} */
/* @media only screen and (max-width: 786px){
    .product_img{
        height: 50vh;
        }
} */
.attribute_active {
    /* background-color: #338d7ea9; */
    border: 1px solid #229986;
    padding: 0;
    border-radius: 5px;
}

.Attribute_color_card {
    margin-right: 1rem;
    display: inline-block;
    display: inline;
    /* zoom:1; */
    vertical-align: top;
    flex: 0 0 auto
}

.Attribute_color_wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    /* height: 80px; */
    display: flex;
    flex-wrap: no-wrap;
}

.Attribute_color {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #bec9c5;
    margin-right: 1rem;
    margin: 0px 5px 0px 5px;
}

.Attribute_color_wrapper::-webkit-scrollbar {
    display: none;
}

.description_wrap {
    color: #89938f;
    font-weight: 500;
    font-size: 16px;
    line-height: 22.4px;
    height: 130px;
    overflow: hidden;
    text-align: justify;
}

.Price_original {
    font-size: 32px;
    font-weight: 500;
}

.Price_discount {
    font-size: 20px;
    font-weight: 500;
}

.discount_view {
    background-color: #edf3ff;
    color: #006399;
    padding: 5px 10px 5px 10px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
}

.Icon_input {
    border-bottom: 1px solid #006399;
    border-top: 1px solid #006399;
    height: 30px;
}

.Icon_input input {
    border: none !important;
    color: #006399;
    /* font-size: 14px; */
    font-weight: 500;
}

.Atum_coin_text {
    background-color: #ffdd8f;
    border: 1px solid #ffb200;
    border-radius: 10px;
    padding: 2px 5px 2px 5px;
    font-size: 10px;
}

.pre_booking_card .p-card {
    border-radius: 10px;
}

.pre_booking_card .p-card .p-card-body {
    padding: 0 !important;
}

.pre_booking_card_heading {
    background-color: #edf3ff;
    padding: 10px 15px 10px 15px;
    border-radius: 10px 10px 0 0;
}

.pre_booking_card_Content {
    padding: 10px 15px 10px 15px;
}
.p-galleria .p-galleria-thumbnail-container{
    background: transparent !important 
}