.Header_Carousel_Banner .carousel-control-prev {
    left: -10% !important;
}

.Header_Carousel_Banner .carousel-control-next {
    right: -10% !important;
}
@media only screen and (max-width: 1024px) {
    .Header_Carousel_Banner .carousel-control-next {
        /* right: 0% !important; */
        display: none;
    }
    .Header_Carousel_Banner .carousel-control-prev {
        /* left: 0% !important; */
        display: none;
    }
  }
.Header_Carousel_Banner .carousel-inner {
    border-radius: 10px !important;
}

.Header_Carousel_Banner .carousel-indicators {
    bottom: -50px !important;

}

.Header_Carousel_Banner .carousel-control-prev,
.carousel-control-next {
    width: 130px !important;
}

.Header_Carousel_Banner .carousel-indicators button {
    background-color: #006B5D !important;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50% !important;
}

.Header_Carousel_Banner .carousel-indicators .active {
    width: 50px !important;
    border-radius: 14px !important;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content img {
    height: 60px !important;
    width: 66px !important;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    display: none;
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 1rem !important;
}