@media only screen and (max-width: 480px){
.cart_flex{
display: flex;
justify-content: space-between;
margin-top: 5px;
}
.cart_flex Button {
    margin-top: 0px !important; 
    margin: 0px 2px 0px 2px;
}
}