.card_active {
    /* background-color: #338d7ea9; */
    border: 1px solid #338D7E;
    padding: 0;
}

.card_disable {
    background-color: #dbe5e48a !important;
}

.pre_bg_img {
    background-image: url('../../Assets/2.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
}

.card_prebooking {
    opacity: 95%;
}

.Paymentmethod_card {
    display: flex;
}

@media only screen and (max-width: 480px) {
    .Paymentmethod_card {
        display: block;
    }
    .card_image_order_conformation{
        height: 200px !important;
    }
}
@media only screen and (max-width: 480px) {
    .confirm-text{
        width: 90% !important;
    }
}

.confirm-text{
    width: 400px;
}
.Coupon_text {
    display: inline;
    width: 100%;
}

.read-or-hide {
    color: rgb(192, 192, 192);
    cursor: pointer;
}

.Card_Order_Process .p-card .p-card-body .p-card-title {
    background-color: #f2f2f2;
    font-size: 18px;
    padding: 10px;
    margin: 0px;
}

.Card_Order_Process .p-card .p-card-body {
    padding: 0;
}

.Card_Order_Process .p-card .p-card-body .p-card-content {
    padding: 0 10px !important;
}

.Stores_data .p-card .p-card-body .p-card-title {
    font-size: 14px !important;
}

.confrim_order_details {
    /* background-image: url('../../Assets/icons/Confirm_layer_icon.svg'); */
    width: 100%;
    /* height: 500px; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.confrim_order_background_img {
    background-size:cover;
    background-repeat: round;
    min-height: 400px;
}
.card_image_order_conformation{
    height: 100px;
}

.border-last:last-child {
border: none !important;
}
