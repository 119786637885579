.layout-sidebar {
    position: sticky;
    min-width: 250px;
    height: calc(100vh - 9rem);
    z-index: 999;
    overflow-y: auto;
    top: 7rem;
    left: 2rem;
    transition: transform .2s, left .2s;
    /* background-color: var(--surface-overlay); */
    border-radius: 12px;
    padding: 0.5rem 1.5rem;
    box-shadow: 0 3px 5px rgb(0 0 0 / 2%), 0 0 2px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 8%);
}

.layout-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.sidebar_menu_item:hover {
    color: #338D7E;
}

.layout-root-menuitem {}

.layout-menu li {
    display: flex;
    align-items: center;
    position: relative;
    outline: 0 none;
    cursor: pointer;
    padding: 0.75rem 1rem;
    border-radius: 12px;
    /* transition: background-color .2s,box-shadow .2s; */

}

.layout-menuitem-root-text {
    font-size: .857rem;
    text-transform: uppercase;
    font-weight: 700;
    /* color: var(--surface-900); */
    margin: 0.75rem 0;
}

.layout-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    /* justify-content: space-between; */
    padding: 0rem 2rem 2rem 4rem;
    transition: margin-left .2s;
}

.active_item {
    color: #338D7E !important;
    font-weight: 700;
}

/* @media only screen and (min-width: 992px){
.layout-main-container {
    margin-left: 250px;
}
} */