@font-face {
  font-family: 'light';
  src: url(./Assets/font/Poppins-Regular.ttf);
}

body {
  font-family: 'Montserrat', sans-serif !important;
}

body::-webkit-scrollbar {
  width: .5rem;
}

body::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px #338D7E; */
}

body::-webkit-scrollbar-thumb {
  background-color: #338D7E;
  /* outline: 1px solid #338D7E; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bg_color_head {
  background-color: #338D7E !important;
}

.bg-color-light {
  background-color: #D9D9D9 !important;
}
.bg-color-grey{
  background-color: #f2f2f2 !important;
}
.text-white {
  color: #fff !important;
}

.text-grey {
  color: #89938f !important;
}

.text-error {
  color: #9bb3af;
}

.color_head {
  color: #338D7E !important;
}

.color_green {
  color: #81b03f;
}

.color_red {
  color: #BA1A1A;
}
.color_blue{
  color: #006399 !important;
}
.color_blue_hover:hover{
  color: #229986;
}
.end-4{
  right: 4%;
}
.p-card .p-card-content {
  padding: 0 !important;
}

.fs-12 {
  font-size: 12px;
}
.fs-16{
  font-size: 16px;
}

.height_35 {
  height: 35px !important;
}

.btn_p {
  /* background-color: #338D7E; */

  --bs-btn-color: #ffffff;
  --bs-btn-bg: #338D7E !important;
  --bs-btn-border-color: #338D7E !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #116859 !important;
  --bs-btn-hover-border-color: #338D7E !important;
  /* --bs-btn-focus-shadow-rgb: 49, 132, 253; */
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #116859 !important;
  --bs-btn-active-border-color: #338D7E !important;
  /* --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); */
  /* --bs-btn-disabled-color: #fff; */
  --bs-btn-disabled-bg: #338d7ea6 !important;
  --bs-btn-disabled-border-color: #338D7E;
}
.primary_btn .p-button{
  color: #ffffff;
  background: #338D7E;
  border: 1px solid #338D7E;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px;
}
.primary_btn .p-button:enabled:active {
  background: #338D7E;
  border: 1px solid #338D7E;
}
.primary_btn .p-button:enabled:hover {
  background: #338D7E;
  border: 1px solid #338D7E;
}
.primary_btn .p-button-danger{
  color: #ffffff;
  background: #a82c2c;
  border: 1px solid #a82c2c;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 10px;
}
.nav-link {
  color: #fff !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .p-component{
  font-family: 'light' !important;
} */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bg_color_head {
  background-color: #229986 !important;
}

.text-white {
  color: #fff !important;
}

.color_head {
  color: #338D7E !important;
}

.color_green {
  color: #81b03f;
}

.color_red {
  color: #BA1A1A;
}
.color_pink {
  color: #E83883;
}

.p-card .p-card-content {
  padding: 0 !important;
}

.fs-12 {
  font-size: 12px;
}
.fs-14{
  font-size: 14px;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-20 {
  font-size: 20px;
}

.fs-7 {
  font-size: .7rem !important;
}

.line-height-14{
  line-height: 14px;
}
.line-height-18{
  line-height: 18px;
}
.cursor_poniter {
  cursor: pointer;
}
.cursor_not_allowed {
  cursor: not-allowed;
}
.fw-500{
  font-weight: 500 !important;
}
.h-40{
  height: 40px !important;
}
.w-70{
  width: 70%;
}
.w-60{
  width: 60%;
}
.w-80{
  width: 80%;
}
.w-90{
  width: 90%;
}
.mt-140 {
  margin-top: 140px;
}
.bottom-10{
  bottom: 10%;
}
.top-90{
  top: 90%;
}
.top-40{
  top: 40%
}
.top-10{
  top: 10% !important;
}
.top-20{
  top: 20%
}
.end-5{
  right: 5%;
}
.height-40{
  height: 25vh;
  min-height: 250px;
}
.height-30{
  height: 25vh;
  max-height: 250px;
}
.border_radius_10 {
  border-radius: 10px;
}

.mr-2 {
  margin-right: 0.5rem;
}

.text-decoration_none {
  text-decoration: none !important;
}

.hr_line {
  border: 1px solid #555857;
  background-color: #555857;
  width: auto;
}

.border_2 {
  border: 2px solid #229986 !important;
}

.btn_p {
  /* background-color: #338D7E; */
  /* height: 46px; */
  --bs-btn-color: hsl(0, 0%, 100%);
  --bs-btn-bg: #229986 !important;
  --bs-btn-border-color: #229986 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #229986 !important;
  --bs-btn-hover-border-color: #229986 !important;
  /* --bs-btn-focus-shadow-rgb: 49, 132, 253; */
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #229986 !important;
  --bs-btn-active-border-color: #229986 !important;
  /* --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); */
  /* --bs-btn-disabled-color: #fff; */
  --bs-btn-disabled-bg: #229986 !important;
  --bs-btn-disabled-border-color: #229986;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #338d7e36 !important;
  color: #ffffff !important;
}

/* .link_data:hover {
  color: #ffffff !important;
} */

.nav-link {
  color: #fff !important;
}

.rotue_link {
  color: #fff !important;
  text-decoration: none;
}

.input_field .p-inputtext {
  width: 100%;
  border: 1px solid #229986 !important;
}
.field .p-inputtext {
  width: 100%;
}
.card_responsive {
  align-items: center;
  height: 100vh;
}

@media only screen and (max-width: 480px) {
  .carousel_disable {
    display: none !important;
  }

  .card_responsive {
    align-items: baseline;
    /* height: 60vh; */
  }

  .card_margin {
    padding: 50px;
  }
}

@media only screen and (max-width: 480px) {
  .Dialog_Box {
    width: 80%;
  }
}

@media only screen and (min-width: 720px) {
  .Dialog_Box {
    width: 60%;
  }
}

.Icon_Btn {
  background-color: #006399 !important;
  padding: 0 !important;
  border: none !important;
  width: 30px;
  height: 30px;
}

.Icon_Btn_Min {

  border-radius: 5px 0px 0px 5px !important;
}

.Icon_Btn_Max {
  border-radius: 0px 5px 5px 0px !important;
}

.Heading_1 {
  color: #2b2f2f;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.Heading_1_suport {
  color: #006399;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
}

.cart_nav{
  position:relative;
}
.cart_nav:before{
  content:"";
  position: absolute;
  top:16px;
  right:0px;
  height: 30px;
  width: 1px;
  background: #BEC9C5;
}
.green_gradient{
  background: linear-gradient(90deg, rgb(34, 153, 134) 40.27%, rgba(223.12, 223.12, 223.12, 0) 80%);
}
.box-shadow-none {
  box-shadow : none !important
}
.p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon{
  color: #fff !important;
}
.bread_crumb .p-breadcrumb {
  border: none;
  border-radius: inherit;
}
.bread_crumb .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #006399;
  font-weight: 500;
}
.border-last {
  border-bottom: 1px solid #BEC9C5;
}
.border-last:last-child {
  border-bottom: none !important;
  }
  .custom-marker {
    display: flex;
    width: 24px;
    height: 24px;
    background-color: #BEC9C5;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
}
.custom-marker-active {
  display: flex;
  width: 24px;
  height: 24px;
  background-color: #229986;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}
.custom-marker_active {
  display: flex;
  width: 24px;
  height: 24px;
  background-color: #bec9c5;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 50%;
  z-index: 1;
}
.p-timeline-event-connector{
  width: 4px !important;
  background-color: #229986;
}
.p-timeline-event-connector-active{
  width: 4px;
  background-color: #229986;
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite{
  display: none !important;
}
.customized-timeline-active .p-timeline-event-separator .p-timeline-event-connector{
  background-color: #229986 !important;
}
@media screen and (max-width: 960px) {
  .customized-timeline .p-timeline-event:nth-child(even) {
      flex-direction: row !important;
  }
  .customized-timeline .p-timeline-event:nth-child(even) .p-timeline-event-content {
      text-align: left !important;
  }
  .customized-timeline .p-timeline-event-opposite {
      flex: 0;
  }
  .customized-timeline .p-card {
      margin-top: 1rem;
  }
}
.Product_Card_Btn .p-button.p-button-secondary.p-button-outlined{
  color:#006399 !important;
  border: 1px solid #006399 !important
}
.dailog_box_responsive{
  width: 50%;
}
.mobile_dailog_box_responsive{
  width: 40%;
}
@media only screen and (max-width: 480px) {
  .dailog_box_responsive {
    width: 85%;
  }
  .mobile_dailog_box_responsive{
    width: 85%;
  }
  .margin_top_2{
    margin-top: 1rem;
  }
  .mobile_none{
    display: none;
  }
}
.bread_crumb .p-breadcrumb{
  padding: 0;
  padding-bottom: 5px;
}
.bread_crumb .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon{
  color: #006399 !important;
}
.bread_crumb .p-breadcrumb ul li .p-menuitem-link:focus{
  box-shadow: none !important;
}