.Product_Card {
    position: relative;
}

.Product_Card .p-card {
    padding: 0px;
    /* border: 1px solid #BEC9C5; */
    border-radius: 10px;
    box-shadow: none;
    height: 250px;
    box-shadow: 0px 0px 10px #00000026 !important;
}

.Product_Card .p-card .p-card-header {
    padding: 0 !important;
}

.Product_Card .p-card .p-card-header img {
    border-radius: 10px 10px 0 0;
    /* border-radius: 10px; */
}

.Product_Card .p-card .p-card-body {
    padding: 8px !important;
}

.Product_Card_Btn .p-button {
    margin: 0;
    padding: 0px 15px !important;
    display: flex;
    justify-content: center;
    justify-items: center;
    height: 30px;
    border-radius: 5px;
    justify-content: end;
}
.Product_Card_Btn .p-button.p-button-outlined{
    border: 1px solid #229986 !important;
    color: #229986 !important;
    font-size: 14px;
    font-weight: 500;
}
.Product_Card .p-card .p-card-footer {
    padding: 0;
}

.card_offer_tag {
    position: absolute;
    /* right: 10px; */
    top: 0px;
    left: 0;
    /* background-image: url('../../Assets/Atum_Icons/Offer_tag.svg'); */
    background-repeat: no-repeat;
    background-color: #E83883;
    width: auto;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 10px 0px 10px 0px;
}

.center {
    font-size: 12px;
    line-height: 1.5;
    color: white;
    padding: 5px;
}

.text_Warp {
    line-height: 18.2px;
    height: 2.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
    -webkit-box-orient: vertical;

}


.w-280 {
    width: 210px;
}