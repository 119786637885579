.hi-100{
    height: 100vh;
}
.fs-9{
    font-size: .9rem;
}
.facebook {
    /* border: 1px dashed #3b5998; */
    border-radius: 100%;
    width: 25px;
    height: 25px;
    padding: 2px;
    cursor: pointer;
}
.google {
    /* border: 1px dashed #DB4437; */
    border-radius: 100%;
    width: 27px;
    height: 27px;
    padding: 0px;
    cursor: pointer;
}