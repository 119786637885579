.link_data {
    color: black;
    text-decoration: none;
}

.card_active_store {
    /* background-color: #338d7ea9; */
    border: 1px solid #338D7E !important;
    padding: 0;
}

.card_disable_store {
    background-color: #dbe5e48a !important;
}

.mt-10 {
    margin-top: 64px;
}

.bg_color_sub_heading {
    background-color: #2aa894;
    height: 50px;
}

.location_header {
    border: 1px solid;
    border-radius: 5px;
}

.h-72 {
    height: 65px;
}

.pl-2 {
    padding-left: .25rem;
}

.Sidebar_header_heading {
    position: absolute;
    top: 15px;
    color: #fff;
}

.Sidebar_header .p-sidebar-header {
    background-color: #2aa894;
}

.fixed-top {
    z-index: 103 !important;
}