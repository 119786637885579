.category_item {
    border-radius: 50%;
    background-color: #D9D9D9;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
}

.bg-color {
    background-color: #229986;
}

/* .category_item:hover{
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.category_item::after{
    background: rgb(104,108,108);
background: linear-gradient(0deg, rgba(104,108,108,0) 0%, rgba(147,145,140,1) 100%);
} */
.banner_image {
    width: 100%;
}

.d-line-grid {
    display: inline-grid;
}

.product-item {
    cursor: pointer;
}

.scrollbar_hidden {
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap !important;
}

.scrollbar_hidden::-webkit-scrollbar {
    display: none;
}
.colse_icon_remove .p-dialog-header .p-dialog-header-icons{
display: none !important;
}
.img_carousel{
    position: relative;
}
.button-contianer-left{
    position: absolute;
    top: 42%;
    left: 2%;
}
.button-contianer-right{
    position: absolute;
    top: 42%;
    right: 2%;
}