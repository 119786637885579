.footer_section{
    background-color: #f5f5f5;
}
.icon_color{
    font-size: 23px !important; 
    margin: 10px;
    color: #fff !important;
}

.hovers {
    color: #fff !important;
    /* line-height: 2rem; */
    text-decoration: none;
    font-weight: 300;
}

.hovers:hover {
    color: #338D7E !important;
}

.contact-head {
    background-color: #77cab9;
    opacity: 0.75;
    margin-top: 2em;
    margin-bottom: 2em;
    height: 100%;
}

.contact-h1 {
    color: #fff;
    font-weight: 900;
    text-align: center;
    padding-top: 1em;
}

.contact-p {
    color: #fff;
    text-align: center;
}

.contact-end {
    margin: 20px;
    padding: 10px;
    color: white;
    border: 2px solid #fff;
    border-radius: 15px;
    text-align: center;
}

.contact-end:hover {
    background-color: white;
    color: #77cab9;
    font-weight: bold;
}

.contact-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contact-contain {
    margin-top: 3em;
}

.about-head {
    margin-top: 4.5em;
    text-align: center;
}

.about-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../../Assets/symbol-02.png');
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 5px;
}

.about-heads1 {
    line-height: 1.2; 
    text-transform: uppercase;
    letter-spacing: 4px;
}

.about-heads2 {
    color: #338d7e;
    line-height: 1;
    letter-spacing: 1px;
}

.about-pads {
    margin-top: 3em;
    margin-bottom: 2em;
}

.about-body {
    background-image: url('../../Assets/bg-01.jpg');
    padding: 1px;
}

.about-body-img {
    background-image: url('../../Assets/other-03.png');
    background-position: right top;
    background-repeat: no-repeat;
    backdrop-filter: opacity(0.5);
}

.about-icons {
    font-size: 20px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 10px;
}
.store_link{
    display: flex;
    align-items: center;
}
.store_link img{
    cursor: pointer;
}
@media only screen and (max-width: 820px){
    .store_link{
        display: block;
        text-align: center;
    }
}