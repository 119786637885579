.text {
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 1.6em;
    line-height: 1.8em;
}

.clr{
    color: #DC143C !important;
    z-index: 10 !important;
    cursor: pointer !important;
}

.card-img {
    height: 25vh !important;
    border-bottom: 1px dashed #ccc !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
}

.head-img {
    height: 40vh;
}

.page-head {
    display: flex; 
    font-weight: 600;
    font-size: 1.5rem;
    /* margin-left: 3%; */
}

.card-title{
    font-size: 18px !important;
    font-weight: 600 !important;;
    text-align: center !important;;
}

.cards {
    box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.4) !important;
    width: 20vw;
}

.page-top{
    margin-top: 4.6rem !important;
}

.page-content{
    margin-left: 1.5rem;
}
.exit-point{
    cursor: pointer;
    width: 10%;
}

.ml-5 {
    margin-left: 6vw;
}